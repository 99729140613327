import React from 'react'
import { observer } from 'mobx-react'
import globalState from './state/globalState'
import {Elements} from '@stripe/react-stripe-js'
import {loadStripe} from '@stripe/stripe-js'

import { getMe } from './api/calls'

import CompanyPreCheck from './components/CompanyPreCheck'
import CompanyPreCheckEvent from './components/CompanyPreCheckEvent'

import InjectedTopup from './pages/injected/injectedTopup'
import InjectedEventAttendees from './pages/injected/injectedEventAttendees'
import InjectedEventCart from './pages/injected/injectedEventCart'
import Subscription from './pages/Subscription'
import CompleteTransaction from './pages/CompleteTransaction'
import MySubscriptions from './pages/MySubscriptions'
import ThreeDSComplete from './pages/ThreeDSComplete'

import logo from './assets/synkd_logo_small.png'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'animate.css'
import './App.css'

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom"

let store = new globalState()

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)

class App extends React.Component {
  async componentDidMount() {
    // When the component first renders, we need to make an API call to check the user is logged in
    let me = await getMe()
    store.setLoggedIn((me.data ? true : false))

    // Everything is done, so we'll set the app status as ready
    store.setAppReady(true)
  }

  render() {
    // If the app isn't ready yet, show a loading screen
    if (!store.appReady) {
      return (
        <div className='App'>
          <div className='main-container'>
            <img src={logo} width='50px' className="App-logo rotate" alt="logo" />
          </div>
        </div>
      )
    }

    // Now we're ready, we can actually render the content & pages/routes
    return (
      <div className="App">
        <Router>
          {!store.loggedIn ?
            <div className="main-container">
              <img src={logo} width='50px' className="App-logo" alt="logo" />
              <p>You do not appear to be logged in.<br />Please go back and try again.</p>
            </div>
          : <Elements stripe={stripePromise}>
              <Switch>
                <Route path='/:companyId/topup/:serviceId/:quantity'>
                  <CompanyPreCheck>
                    <InjectedTopup globalState={store} />
                  </CompanyPreCheck>
                </Route>
                <Route path='/:eventId/event-attendees'>
                  <CompanyPreCheckEvent>
                    <InjectedEventAttendees globalState={store} />
                  </CompanyPreCheckEvent>
                </Route>
                <Route path='/:eventId/event-cart'>
                  <CompanyPreCheckEvent>
                    <InjectedEventCart globalState={store} />
                  </CompanyPreCheckEvent>
                </Route>
                <Route path={[
                  '/:companyId/subscriptions'
                ]}>
                  <CompanyPreCheck>
                    <MySubscriptions globalState={store} />
                  </CompanyPreCheck>
                </Route>
                <Route path='/:companyId/subscription/:internalProductId'>
                  <CompanyPreCheck>
                    <Subscription globalState={store} />
                  </CompanyPreCheck>
                </Route>
                <Route path='/complete-transaction'>
                  <CompleteTransaction globalState={store} />
                </Route>
                <Route path="/3DS-authentication-complete">
                  <ThreeDSComplete globalState={store}/>
                </Route>
                <Route path="/">
                  <header className="main-container">
                    <img src={logo} width='50px' className="App-logo" alt="logo" />
                    <p>
                      Oops, you appear to have hit the wrong page.<br />If you came here from clicking a link in your account, please try again.
                    </p>
                  </header>
                </Route>
              </Switch>
            </Elements>
          }
        </Router>
      </div>
    )
  }
}

export default observer(App)
