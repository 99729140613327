import React from 'react'

import { withRouter } from "react-router"
import logo from '../assets/synkd_logo.png'


const queryString = require('query-string')

class ThreeDSComplete extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

        }
    }

    async componentDidMount() {
        console.log("posting: 3DS-authentication-complete")
        window.parent.postMessage('3DS-authentication-complete');
    }



    render() {
        return (
            <div className='main-container'>
               <p>Processing.</p>
            </div>
        )
    }
}

export default withRouter(ThreeDSComplete)
