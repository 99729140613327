const axios = require('axios').default

/**
 * Make a request to the GraphQL API with specific data
 * @param {String} data - The query or mutation to pass to the API
 * @author [Jayden Bailey](github.com/jaydenkieran)
 */
const graphQLRequest = async (data) => {
    const res = await axios.post(process.env.REACT_APP_GRAPHQL_ENDPOINT, {query: data}, {
        withCredentials: true,
        headers: {
            'Content-Type': 'application/json'
        }
    })

    return res
}

/**
 * Retrieves information about the currently logged in user
 * @author [Jayden Bailey](github.com/jaydenkieran)
 */
export const getMe = async () => {
    const res = await graphQLRequest(`{ me }`)
    return res.data
}

export const calculateTax = async (price, countryCode) => {
    const res = await graphQLRequest(`{ calculateTax(price: ${price}, countryCode: "${countryCode}") }`)
    return res.data
}

export const calculateTaxByCurrency = async (price, currency) => {
    console.log("calculateTaxByCurrency: ", price, currency)
    const res = await graphQLRequest(`{ calculateTaxByCurrency(price: ${price}, currency: "${currency}") }`)
    return res.data
}

/**
 * Retrieves the company's saved cards
 * @param {String} companyId - Mongo object ID for the company
 * @author [Jayden Bailey](github.com/jaydenkieran)
 */
export const getCompanyCards = async (companyId) => {
    const res = await graphQLRequest(`{ getPaymentCards(companyId: "${companyId}") }`)
    console.log('get company cards', res)
    return res.data
}
export const getCompanyCardsPersonal = async () => {
    const res = await graphQLRequest(`{ getPaymentCardsPersonal }`)
    console.log('get company cards', res)
    return res.data
}

export const getCompanyBillingInfo = async (companyId) => {
    const res = await graphQLRequest(`{ getCompanyBillingInfo(companyId: "${companyId}") }`)
    return res.data
}

export const getCompanyBillingInfoByEvent = async (eventId) => {
    const res = await graphQLRequest(`{ getCompanyBillingInfoByEvent(eventId: "${eventId}") }`)
    return res.data
}

export const getCompanySubscriptions = async (companyId) => {
    const res = await graphQLRequest(`{ getCompanySubscriptions(companyId: "${companyId}") }`)
    return res.data
}

export const cancelSubscription = async (subscriptionId) => {
    const res = await graphQLRequest(`
        mutation { cancelCompanySubscription(subscriptionId: "${subscriptionId}") }
    `)
    return res.data
}

/**
 * Gets the pricing info for a service based on the company that requested it.
 * Prices will be returned in their currency, if it is even possible to purchase this
 * amount of the service/top-up.
 * @param {String} companyId - Mongo object ID for the company
 * @param {String} serviceId - Mongo object ID for the service
 * @param {Int} quantity - The quantity of this service that the user is requesting
 */
export const getMyPricingInfoForService = async (companyId, serviceId, quantity) => {
    const res = await graphQLRequest(`
        {
            getMyPricingInfoForService(data: {
                companyId: "${companyId}",
                serviceId: "${serviceId}",
                quantity: ${quantity}
            })
        }
    `)
    return res.data
}

/**
 * Starts a new topup transaction (using the company's default currency) and returns
 * the created Stripe PaymentIntent object
 * @param {String} companyId - Mongo object ID for the company
 * @param {String} serviceId - Mongo object ID for the service
 * @param {Int} quantity - The quantity of this service to buy
 */
export const startTopupTransaction = async (companyId, serviceId, quantity, cardStripeId, couponId) => {
    const res = await graphQLRequest(`
        mutation {
            startTopupTransaction(data: {
                companyId: "${companyId}",
                serviceId: "${serviceId}",
                quantity: ${quantity},
                cardStripeId: "${cardStripeId}",
                couponId: "${couponId}"
            })
        }
    `)
    return res.data
}

export const getEvent = async (eventId) => {
    const res = await graphQLRequest(`
        {
            getEvent(data: {
                id: "${eventId}",
                eventType: PLATFORM_EVENT
            })
        }
    `)
    return res.data
}

export const getEventCartItem = async (eventId) => {
    const res = await graphQLRequest(`
        {
            getEventCartItem(data: {
                eventId: "${eventId}"
            })
        }
    `)
    return res.data
}

export const getAttendeeService = async (eventId) => {
    const res = await graphQLRequest(`
        {
            getAttendeeService(data: {
                eventId: "${eventId}"
            })
        }
    `)
    return res.data
}

export const buyAttendees = async (eventId, quantity, cardStripeId, couponId) => {
    const res = await graphQLRequest(`
        mutation {
            buyAttendees(data: {
                eventId: "${eventId}",
                quantity: ${quantity},
                cardStripeId: "${cardStripeId}",
                couponId: "${couponId}"
            })
        }
    `)
    return res.data
}

export const checkoutCart = async (eventId, cardStripeId, couponId) => {
    const res = await graphQLRequest(`
        mutation {
            checkoutEventCartItemWithCard(data: {
                eventId: "${eventId}",
                cardStripeId: "${cardStripeId}"
                couponId: "${couponId}"
            })
        }
    `)
    return res.data
}

/**
 * Gets product and pricing info for the product that the company is purchasing
 * @param {String} companyId - Mongo object ID for the company
 * @param {String} internalProductId - Mongo object ID for the product
 */
export const getInternalProductInfoForCompany = async (companyId, internalProductId) => {
    const res = await graphQLRequest(`
        {
            getInternalProductInfoForCompany(data: {
                companyId: "${companyId}",
                databaseId: "${internalProductId}"
            })
        }
    `)
    return res.data
}

/**
 * Gets pricing info from Stripe for a Price object
 * @param {String} stripePriceId -  Stripe ID for the Price
 */
export const getPricingInfo = async (stripePriceId) => {
    const res = await graphQLRequest(`
        {
            getPricingInfo(priceId: "${stripePriceId}")
        }
    `)
    return res.data
}

export const getBillingCouponInfoByPromoCode = async (promoCode) => {
    const res = await graphQLRequest(`
        {
            getBillingCouponInfoByPromoCode(promoCode: "${promoCode}")
        }
    `)
    return res.data
}

export const createCompanySubscription = async (companyId, items, stripePaymentMethodId, coupon=null) => {
    let couponStr = coupon ? coupon : ''

    const res = await graphQLRequest(`
        mutation {
            createCompanySubscription(data: {
                companyId: "${companyId}",
                items: ${JSON.stringify(items)},
                stripePaymentMethodId: "${stripePaymentMethodId}",
                coupon: "${couponStr}"
            })
        }
    `)
    return res.data
}