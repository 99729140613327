import React from 'react'
import { withRouter } from "react-router"

import { getCompanyBillingInfoByEvent, getEvent } from '../api/calls'
import { getMSLURL } from '../util'
import { ReactComponent as CrossSvg } from '../assets/svg/close.svg'

import logo from '../assets/synkd_logo.png'

class CompanyPreCheckEvent extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            billingEmail: '',
            billingAddress: {},
            stripeAccountId: '',
            companyId: null,
            ready: false
        }
        this.closeIframe = this.closeIframe.bind(this)

    }

    async componentDidMount() {
        const { match } = this.props

        const eventData = await getEvent(match.params.eventId)
        this.setState({
            companyId: eventData.data.getEvent.organiser.company.id
        })

        // check company billing info
        let billingInfo = await getCompanyBillingInfoByEvent(match.params.eventId)
        if (billingInfo.data && billingInfo.data.getCompanyBillingInfoByEvent) {
            this.setState({
                billingEmail: billingInfo.data.getCompanyBillingInfoByEvent.billingEmail,
                billingAddress: billingInfo.data.getCompanyBillingInfoByEvent.address,
                stripeAccountId: billingInfo.data.getCompanyBillingInfoByEvent.stripeAccountId,
            })
        }

        this.setState({
            ready: true
        })
    }
    closeIframe() {
        let url =
            window.location != window.parent.location
                ? document.referrer
                : document.location.href;
        /* window.parent.postMessage("close-iframe", 'https://localhost:8080/admin/company/adminpackageoptions'); */
        window.parent.postMessage("close-iframe", '*');
    };
    render() {
        if (!this.state.ready) {
            return <p>Please wait...</p>
        }

        // if (this.state.stripeAccountId) {
            return <>
                {this.props.children}
            </>
        // } else {
        //     return (
        //         <div className='main-container'>
        //             <div className='content-container animate__animated animate__fadeIn' style={{ minWidth: 500 }}>
        //                 <div
        //                     // className='action-btns'
        //                     style={{ display:'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12 }}
        //                 >
        //                     <img src={logo} style={{ height: 30 }} className="App-logo invertfilter" alt="logo" />
        //                     <CrossSvg
        //                         width='40px'
        //                         height='40px'
        //                         className='p-2 rounded'
        //                         color='whitesmoke'
        //                         onClick={() => this.closeIframe()}
        //                         //   variant="danger" 
        //                         //   className='cancel-btn'
        //                         style={{ backgroundColor: '#ed1c24', cursor: 'pointer', marginLeft: "8px" }}
        //                     />
        //                 </div>
        //                 <p style={{ marginTop: 12 }}>Please set this company's billing information <br />in <a href={`${getMSLURL()}/company/${this.state.companyId}/billing/info`} target='_blank' rel='noopener noreferrer'>My Synkd Life</a> first</p>
                    
        //                 {!this.state.billingEmail &&
        //                     <><p style={{ marginTop: 12 }}>Company is missing a billing email address.</p><p> <a href={`${getMSLURL()}/company/${this.state.companyId}/billing/info`} target='_blank' rel='noopener noreferrer'>Add one now</a>.</p></>
        //                 }
                        
        //                 {Object.keys(this.state.billingAddress || {}).length === 0 &&
        //                     <> <p style={{ marginTop: 12 }}>Company is missing an address. For the purposes of calculating tax, we need the company to have an address set. </p> <p><a href={`${getMSLURL()}/company/${this.state.companyId}/home/info`} target='_blank' rel='noopener noreferrer'>Add one now</a>. </p></>
        //                 }
        //             </div>
        //         </div>
        //     )
        // }
    }
}

export default withRouter(CompanyPreCheckEvent)