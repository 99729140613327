import React from 'react'
import { withRouter } from "react-router"

import logo from '../assets/synkd_logo.png'

import ButtonGroup from 'react-bootstrap/ButtonGroup'
import Button from 'react-bootstrap/Button'

import { getCompanySubscriptions, cancelSubscription } from '../api/calls'

class MySubscriptions extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            companyId: '',
            subscriptionId: '',
            subscriptionName: '',
            subscriptionPeriodEnd: '',
            subscriptions: [],
            doCancel: false,
            cancelSuccess: false
        }

        this.renderSubscriptions = this.renderSubscriptions.bind(this)
        this.renderSubscriptionStatus = this.renderSubscriptionStatus.bind(this)
        this.setSubscriptionDetails = this.setSubscriptionDetails.bind(this)
        this.doGoBack = this.doGoBack.bind(this)
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevState.doCancel === false && this.state.doCancel === true) {
            // Cancel the subscription
            await this.doCancel()
        }
    }

    async componentDidMount() {
        const { match } = this.props
        this.setState({
            companyId: match.params.companyId
        })

        let res = await getCompanySubscriptions(match.params.companyId)
        if (res && res.data) {
            this.setState({
                subscriptions: res.data.getCompanySubscriptions
            })

            if (match.params.subscriptionId) {
                // If there is a subscription ID provided...
                // Grab information on the subscription and save in state
            } else {
                // If no subscription ID provided...
                // We need to prompt them to select an active subscription to cancel
            }
        } else {
            // TODO: error because company billing info could not be retrieved
        }
    }

    async doCancel() {
        let res = await cancelSubscription(this.state.subscriptionId)
        if (res) {
            // TODO: anything else we need to do to check that it was cancelled
            this.setState({
                cancelSuccess: true
            })
        }
    }

    doGoBack() {
        this.setState({
            subscriptionId: null,
            cancelSuccess: false
        })
    }

    setSubscriptionDetails(subs) {
        this.setState({
            subscriptionId: subs.id,
            subscriptionName: subs.plan.product.name,
            subscriptionPeriodEnd: new Date(subs.current_period_end * 1000)
        })
    }

    renderSubscriptionStatus(status) {
        switch (status) {
            case 'active':
                return <span className="badge badge-success">Active</span>
            default:
                break
        }
    }

    renderSubscriptions() {
        return this.state.subscriptions.map(subs => {
            let startDate = new Date(subs.current_period_start * 1000) // * 1000 as it's a unix timestamp
            let endDate = new Date(subs.current_period_end * 1000) // * 1000 as it's a unix timestamp
            let cost = `${subs.plan.amount} ${subs.plan?.currency.toUpperCase()}/${subs.plan.interval}`

            return <div className="card subs-card" key={subs.id}>
                <div className="card-body">
                    <h3>{subs.plan.product.name}</h3>
                    <p>Started: <strong>{startDate.toString()}</strong><br />
                    Renews: <strong>{endDate.toString()}</strong></p>
                    <p>Price: <strong>{cost}</strong></p>
                    <p>Status: <strong>{this.renderSubscriptionStatus(subs.status)}</strong></p>
                    <button type="button" className="btn btn-danger" onClick={() => this.setSubscriptionDetails(subs)}>Cancel</button>
                </div>
            </div>
        })
    }

    render() {
        return (
            <div className='main-container'>
                <img src={logo} style={{marginBottom: '1em'}} width='100px' className="App-logo" alt="logo" />
                <div className='content-container animate__animated animate__fadeIn'>
                    {/* <h3 style={{marginTop: '10px'}}>{this.state.subscriptionId ? 'Cancel subscription' : 'Which subscription would you like to cancel?'}</h3> */}
                    {this.state.subscriptionId ?
                        <>
                            {this.state.doCancel ? <p>{this.state.cancelSuccess ? 'Successfully cancelled your subscription.' : 'We are attempting to cancel your subscription. Please wait.'}</p> : <>
                                    <p>You are about to cancel your <strong>{this.state.subscriptionName}</strong> subscription.</p>
                                    <p>By cancelling your subscription, you will continue to receive your benefits until the end of your current billing period, on <strong>{this.state.subscriptionPeriodEnd.toString()}</strong>. After that, you will lose access to your benefits and will not be charged further.</p>
                                    <div className='action-btns'>
                                        <Button variant="synkdPrimary" disabled={this.state.doCancel} onClick={() => this.setState({doCancel: true})} className='pay-btn'>Cancel subscription</Button>{' '}
                                        <Button variant="danger" className='cancel-btn' onClick={() => this.doGoBack()}>Go back</Button>{' '}
                                    </div>
                                </>
                            }
                        </>
                    :
                        <>
                            {this.state.subscriptions.length ? this.renderSubscriptions() : <div className='error'>You have no active subscriptions.</div>}
                        </>
                    }
                </div>
            </div>
        )
    }
}

export default withRouter(MySubscriptions)
