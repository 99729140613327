import React from 'react'
import { withRouter } from "react-router"

import logo from '../assets/synkd_logo.png'

const queryString = require('query-string')

class Success extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            willRedirect: false
        }
    }

    async componentDidMount() {
        const parsedQuery = queryString.parse(this.props.location.search)
        console.log('parsedQuery', parsedQuery)
        if (parsedQuery['callback']) {
            this.setState({
                willRedirect: true
            })

            // Redirect to the callback URL
            await new Promise(resolve => setTimeout(resolve, 1000 * 3)) // timeout for 3 sec
            window.location.replace(parsedQuery['callback'])
        }
    }

    render() {
        return (
            <div className='main-container'>
            <img src={logo} style={{marginBottom: '1em'}} width='100px' className="App-logo" alt="logo" />
            <div className='content-container animate__animated animate__fadeIn'>
                <h3 style={{marginTop: '10px'}}>Thank you for Subscribing.</h3>
                {this.props.children}
                {this.state.willRedirect && <p>You will be redirected in a few seconds.</p>}
            </div>
        </div>
        )
    }

}

export default withRouter(Success)