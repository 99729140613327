import React from 'react'
import { withRouter } from "react-router"

import { getCompanyBillingInfo } from '../api/calls'
import { getMSLURL } from '../util'
import { ReactComponent as CrossSvg } from '../assets/svg/close.svg'

import logo from '../assets/synkd_logo.png'

class CompanyPreCheck extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            billingEmail: '',
            billingAddress: {},
            companyId: null,
            ready: false
        }
        this.closeIframe = this.closeIframe.bind(this)

    }

    async componentDidMount() {
        const { match } = this.props
        this.setState({
            companyId: match.params.companyId
        })

        // check company billing info
        let billingInfo = await getCompanyBillingInfo(match.params.companyId)
        if (billingInfo.data && billingInfo.data.getCompanyBillingInfo) {
            this.setState({
                billingEmail: billingInfo.data.getCompanyBillingInfo.billingEmail,
                billingAddress: billingInfo.data.getCompanyBillingInfo.address
            })
        }

        this.setState({
            ready: true
        })
    }
    closeIframe() {
        let url =
            window.location != window.parent.location
                ? document.referrer
                : document.location.href;
        /* window.parent.postMessage("close-iframe", 'https://localhost:8080/admin/company/adminpackageoptions'); */
        window.parent.postMessage("close-iframe", '*');
    };
    render() {
        if (!this.state.ready) {
            return <p>Please wait...</p>
        }

        if (this.state.billingEmail && Object.keys(this.state.billingAddress).length > 0) {
            return <>
                {this.props.children}
            </>
        } else {
            return (
                <div className='main-container'>
                    <div className='content-container animate__animated animate__fadeIn' style={{ minWidth: 500 }}>
                        <div
                            // className='action-btns'
                            style={{ display:'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 12 }}
                        >
                            <img src={logo} style={{ height: 30 }} className="App-logo invertfilter" alt="logo" />
                            <CrossSvg
                                width='40px'
                                height='40px'
                                className='p-2 rounded'
                                color='whitesmoke'
                                onClick={() => this.closeIframe()}
                                //   variant="danger" 
                                //   className='cancel-btn'
                                style={{ backgroundColor: '#ed1c24', cursor: 'pointer', marginLeft: "8px" }}
                            />
                        </div>
                        <p style={{ marginTop: 12 }}>Please set Company Billing Information <br /><a href={`${getMSLURL()}/company/${this.state.companyId}/billing/info`} target='_blank' rel='noopener noreferrer'>Add One Now</a></p>                    
                        {!this.state.billingEmail &&
                            <><p style={{ marginTop: 12 }}>Company is missing a billing email address.</p><p> <a href={`${getMSLURL()}/company/${this.state.companyId}/billing/info`} target='_blank' rel='noopener noreferrer'>Add one now</a>.</p></>
                        }
                        
                        {Object.keys(this.state.billingAddress || {}).length === 0 &&
                            <> <p style={{ marginTop: 12 }}>Company is missing an address. For the purposes of calculating tax, we need the company to have an address set. </p> <p><a href={`${getMSLURL()}/company/${this.state.companyId}/home/info`} target='_blank' rel='noopener noreferrer'>Add one now</a>. </p></>
                        }
                    </div>
                </div>
            )
        }
    }
}

export default withRouter(CompanyPreCheck)