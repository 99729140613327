import React from 'react'
import {ElementsConsumer} from '@stripe/react-stripe-js'

import EventCart from '../EventCart'

const injectedEventCart = () => {
    return (
        <ElementsConsumer>
            {({elements, stripe}) => (
                <EventCart elements={elements} stripe={stripe} />
            )}
        </ElementsConsumer>
    )
}

export default injectedEventCart