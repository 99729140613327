import {loadStripe} from '@stripe/stripe-js'

class Stripe {
    constructor() {
        this._stripe = null
    }

    async loadStripe() {
        this._stripe = await loadStripe(process.env.REACT_APP_STRIPE_KEY)
    }
}

export default Stripe