import { makeObservable, observable, computed, action } from 'mobx'

class globalState {
    appReady = false
    loggedIn = false

    constructor() {
        makeObservable(this, {
            appReady: observable,
            loggedIn: observable,
            setLoggedIn: action,
            setAppReady: action
        })
    }

    /**
     * Used for setting the user's current logged in status.
     * @param {Boolean} bool - true/false
     */
    setLoggedIn(bool) {
        this.loggedIn = bool
    }

    /**
     * Used for setting the app's ready status. This should be called after all initial load stuff has finished.
     * @param {Boolean} bool - true/false
     */
    setAppReady(bool) {
        this.appReady = bool
    }
}

export default globalState