import React from 'react'

import logo from '../assets/synkd_logo.png'

function Error(props) {
    return (
        <div className='main-container'>
            <img src={logo} style={{marginBottom: '1em'}} width='100px' className="App-logo" alt="logo" />
            <div className='content-container animate__animated animate__fadeIn'>
                <h3 style={{marginTop: '10px'}}>There was a problem</h3>
                {props.children}
            </div>
        </div>
    )
}

export default Error