import React from 'react'

import logo from '../assets/synkd_logo_small.png'

function Loading(props) {
    return (
        <div className='main-container'>
            <img src={logo} width='50px' className="App-logo rotate" alt="logo" />
        </div>
    )
}

export default Loading